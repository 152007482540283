var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap"},[_c('HeaderBox',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" 报名管理 / 场次列表 ")]},proxy:true}])}),_c('a-table',{staticClass:"table-template",attrs:{"rowKey":function (item,index){ return index; },"columns":_vm.columns,"loading":_vm.loading,"data-source":_vm.tableData,"locale":{emptyText: '暂无数据'},"pagination":{
        total:_vm.total,
        current:_vm.queryParams.pageNum,  
        defaultpageSize:_vm.queryParams.pageSize, 
        showSizeChanger: true,
        showTotal: function(total, range){
          return ("共" + total + "条")
        }
      }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, index){return [_vm._v(" "+_vm._s((_vm.queryParams.pageNum - 1) * _vm.queryParams.pageSize + index + 1)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }