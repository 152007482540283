<template>
  <!-- 报名管理 -->
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        报名管理 / 场次列表
      </template>
    </HeaderBox>

    <a-table 
        class="table-template"
        :rowKey="(item,index)=>index"
        :columns="columns" 
        :loading="loading"
        :data-source="tableData" 
        :locale="{emptyText: '暂无数据'}" 
        @change="onPage"
        :pagination="{
          total:total,
          current:queryParams.pageNum,  
          defaultpageSize:queryParams.pageSize, 
          showSizeChanger: true,
          showTotal: function(total, range){
            return `共${total}条`
          }
        }">
        <!-- 序号 -->
      <template
        slot="index"
        slot-scope="item, row, index"
      >
        {{ (queryParams.pageNum - 1) * queryParams.pageSize + index + 1 }}
      </template>
    </a-table>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "场次名称",
    align: "center",
    dataIndex: "batchName",
  },
  {
    title: "城市",
    align: "center",
    dataIndex: "productName",
  },
  {
    title: "活动地点",
    align: "center",
    dataIndex: "totalNum",
  },
  {
    title: "报名人数限制",
    align: "center",
    dataIndex: "getNum",
  },
  {
    title: "已报名人数",
    align: "center",
    dataIndex: "redeemNum",
  },
  {
    title: "报名开始时间",
    align: "center",
    dataIndex: "nickName4",
  },
  {
    title: "报名结束时间",
    align: "center",
    dataIndex: "nickName3",
  },
  {
    title: "活动开始时间",
    align: "center",
    dataIndex: "nickName2",
  },
  {
    title: "活动结束时间",
    align: "center",
    dataIndex: "nickName1",
  },
];
export default {
  // 可用组件的哈希表
  components: {HeaderBox},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      columns,
      loading:false,
      tableData:[],
      total:0,
      queryParams: {
        batchName:'',
        pageNum: 1, //页码
        pageSize: 10, // 页数
      },
    }
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e){
      this.queryParams.pageNum = e.current
      this.queryParams.pageSize = e.pageSize
      this.getList()
    },

    onSearch() {
      this.queryParams.pageNum = 1;
      this.getList();
    },

    // 查询兑换券列表
    getList(e) {
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/redeem/list",
        params: this.queryParams,
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.total = res.data.total;
          this.tableData = res.data.records;
        }
      });
    }
  },
  // 生命周期-实例创建完成后调用
  created () { 
    this.getList()
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.btn {
  margin: 0 24px 14px 0;
}
</style>
